<template>
  <span>
    <navbar />
    <ribbon />
    <div class="container-fluid main-container" :class="additionalFooterClass">
      <router-view />
    </div>
    <FloatingMenu />
    <conversation-list v-if="authorizedUser" />
    <footer-view />
  </span>
</template>
<script>
import { mapState } from "vuex";
import FloatingMenu from "../components/FloatingMenu.vue";
import ConversationList from "../components/chat/ConversationList.vue";
import { initiateEcho } from "../utils/echo.js";
import LogRocket from "logrocket";

export default {
  name: "Authed",
  components: {
    FloatingMenu,
    ConversationList,
  },
  created() {
    initiateEcho();
  },
  mounted() {
    if (window.location.origin === "https://portal.anchor.comm3dev.com") {
      LogRocket.init("mcbt2r/c3");
      if (this.authorizedUser) {
        LogRocket.identify(this.authorizedUser.id, {
          name: this.authorizedUser.name,
          email: this.authorizedUser.email,
        });
      }
    }
  },
  computed: {
    ...mapState({
      authorizedUser: (state) => state.auth.user,
    }),
    additionalFooterClass: function () {
      return `${(this.$route.name || "").replace(/\./g, "-")}-main-container`;
    },
    isAllowed: function () {
      if (
        this.authorizedUser &&
        (this.authorizedUser.isAdmin || this.authorizedUser.isManagingAdmin)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
