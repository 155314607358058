<template>
  <div>
    <div class="container">
      <room v-if="routeName == 'room'" class="mt-3" />
      <clinical-note v-if="routeName == 'clinical-note'" class="mt-3" />
      <invoice v-if="routeName == 'invoice'" class="mt-3" />
      <okaya-return v-if="routeName == 'okaya-return'" />
    </div>
  </div>
</template>
<script>
import Room from "../views/public/Room.vue";
import ClinicalNote from "../views/public/ClinicalNote.vue";
import Invoice from "../views/public/Invoice.vue";
import OkayaReturn from "../views/public/OkayaReturn.vue";

export default {
  name: "Guest",
  components: {
    Room,
    ClinicalNote,
    Invoice,
    OkayaReturn,
  },
  computed: {
    routeName: function () {
      return this.$route.name;
    },
  },
};
</script>
