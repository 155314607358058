<template>
  <div>
    <div class="container">
      <div class="d-flex justify-content-center">
        <div class="mt-3">
          <div :class="isLoading || true ? 'img-placeholder' : ''">
            <img
              v-if="!isLoading"
              :src="companyLogo || companyLogoPlaceholder"
              class="rounded site-rounded-logo"
            />
          </div>
        </div>
      </div>
    </div>
    <router-view />
    <div class="container">
      <version class="mx-auto small text-muted text-center" />
    </div>
  </div>
</template>

<script>
import Version from "../components/Version";
import { mapState } from "vuex";
export default {
  name: "Guest",
  components: {
    Version,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  computed: {
    ...mapState({
      companyLogo: (state) => state.settings.companyLogo,
      isLoading: (state) => state.settings.isLogoLoading,
    }),
  },
};
</script>

<style scoped>
.img-placeholder {
  height: 180px;
}
</style>
