import axios from "axios";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { config } from "./config.js";

import * as autosize from "autosize";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import VTooltip from "v-tooltip";
import fetchIntercept from "fetch-intercept";
import CKEditor from "@ckeditor/ckeditor5-vue2";

import * as maphilight from "maphilight";
import $ from "jquery";
import "popper.js";

window.$ = window.jQuery = $;
import "bootstrap";

import * as dayjs from "dayjs";
import VModal from "vue-js-modal";
import Cleave from "cleave.js";

import "vue-datetime/dist/vue-datetime.css";

import VCalendar from "v-calendar";

import { VueFormBuilderPlugin } from "@ray-solutions/v-form-builder";
import "@ray-solutions/v-form-builder/dist/v-form-builder.css";
import Swal from "sweetalert2";

import Vue2Filters from "vue2-filters";
import ImageMarkupControl from "./components/ImageTemplate/ImageMarkupControl";
import ImageMarkupConfigControl from "./components/ImageTemplate/ImageMarkupConfigControl";

window.onbeforeprint = () => {
  const textareas = document.querySelectorAll(".vue-form-renderer textarea");
  textareas.forEach((textarea) => {
    const div = document.createElement("div");
    div.className = "print-textarea-div form-control";
    div.textContent = textarea.value;
    textarea.parentNode.insertBefore(div, textarea.nextSibling);
  });
  window.$(".client-notes .vue-form-renderer .multi-select").each(function () {
    window.$(this).find(".option:not(.selected)").hide();
    window.$(this).css("min-height", "60px");
  });
};

window.onafterprint = () => {
  const divs = document.querySelectorAll(".print-textarea-div");
  divs.forEach((div) => {
    div.parentNode.removeChild(div);
  });
  window.$(".client-notes .vue-form-renderer .multi-select").each(function () {
    window.$(this).find(".option:not(.selected)").show();
  });
};
config.loadConfig().then((configResult) => {
  store.state["appConfig"] = configResult;

  Vue.config.productionTip = false;

  const httpClient = axios.create({
    baseURL: `${configResult.api}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  httpClient.interceptors.request.use((config) => {
    if (window.Echo) {
      config.headers["X-Socket-ID"] = window.Echo.socketId();
    }
    const token = localStorage.getItem("ARSS_TOKEN");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  });

  httpClient.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response) {
        if (
          (error.response.data.error || {}).message == "Access denied" ||
          (error.response.data.error || {}).message ==
            "This action is unauthorized."
        ) {
          Swal.fire({
            title: "Access Denied",
            html: "Looks like your permissions weren't properly configured for some areas of the app, please contact the admin at <a href='mailto:support@communitycareconnection.com'>support@communitycareconnection.com</a> to fix this.",
            icon: "error",
          }).then(() => {
            if (error.response.data.error.route.methods[0] === "GET") {
              window.location.href = "/";
            }
          });

          return Promise.reject({ accessDenied: true });
        }

        if (
          (error.response.data.error || {}).message == "Service Unavailable"
        ) {
          Swal.fire({
            title: "Maintenance Mode",
            html: "We are currently performing maintenance on the server, please try again later.",
            icon: "error",
          }).then(() => {
            if (window.location.path != "/login") {
              localStorage.removeItem("ARSS_TOKEN");
              localStorage.removeItem("USER_TYPE");
              localStorage.removeItem("C3_LOGIN");
              window.location.href = "/login";
            }
          });
          return Promise.reject({ accessDenied: true });
        }

        if (
          ((error.response.data || {}).error || {}).message ==
            "Unauthenticated." ||
          ((error.response.data || {}).error || {}).message == "token expired"
        ) {
          if (window.location.path != "/login") {
            localStorage.removeItem("ARSS_TOKEN");
            localStorage.removeItem("USER_TYPE");
            localStorage.removeItem("C3_LOGIN");
            window.location.href = "/login";
          }
          return Promise.reject();
        }
        return Promise.reject(error.response);
      }

      return Promise.reject(error);
    }
  );
  fetchIntercept.register({
    request: function (url, config) {
      const token = localStorage.getItem("ARSS_TOKEN");
      if (token && config) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return [url, config];
    },
  });
  Vue.prototype.$http = httpClient;
  store.$http = httpClient;

  // jQuery to Vue
  Vue.prototype.$ = $;

  [
    VModal,
    VueSweetalert2,
    VCalendar,
    Vue2Filters,
    maphilight,
    CKEditor,
    {
      componentPrefix: "vc",
    },
  ].forEach((x) => Vue.use(x));

  Vue.use(VTooltip, {
    defaultTrigger: window.innerWidth > 768 ? "hover focus click" : "click",
  });

  Vue.use(VueFormBuilderPlugin, {
    controls: {
      imageMarkup: {
        name: "Image Drawer",
        description: "Image Markup Editor (Draw & Paint)",
        fieldComponent: ImageMarkupControl,
        configComponent: ImageMarkupConfigControl,
        configData: {
          canvasWidth: 400,
          canvasHeight: 400,
          hasImageBorder: true,
          imagePosition: "left",
          containerClass: "col-md-12 md-layout-item md-size-100",
          imageUrl: "",
        },
        rendererDefaultData() {
          return false;
        },
      },
    },
  });

  // Load global components
  const files = require.context("./components/", true, /\.vue$/i);
  files.keys().map((key) => {
    Vue.component(key.split("/").pop().split(".")[0], files(key).default);
  });

  /* DIRECTIVES AND FILTERS */
  Vue.directive("autosize", {
    bind: (el) => {
      setTimeout(function () {
        autosize(el);
      }, 50);
    },
  });

  Vue.directive("cleave", {
    inserted: (el, binding) => {
      if (binding.value) {
        el.cleave = new Cleave(el, binding.value || {});
      }
    },
    update: (el) => {
      if (typeof el.cleave !== "undefined") {
        const event = new Event("input", { bubbles: true });
        setTimeout(function () {
          el.value = el.cleave.properties.result;
          el.dispatchEvent(event);
        }, 100);
      }
    },
  });

  Vue.filter("date", function (value, format) {
    if (!value) return "";
    return dayjs(value).format(format);
  });

  Vue.filter("nl2br", function (value) {
    const result = value.split("\n").join("<br>");
    return result;
  });

  Vue.filter("maxEllipsis", function (value, max) {
    const nlNumber = value.split("\n").length - 1;
    const hrNumber = value.split("-------------------------------").length - 1;
    const maxLength = nlNumber * 2 + hrNumber * 31;
    if (value.length <= max + maxLength) {
      return value;
    }
    let result = `${value.substring(0, max + maxLength)}`;
    if (result.lastIndexOf("\\") === result.length - 1) {
      result = `${result}n`;
    }

    result = `${result}...`;
    return result;
  });

  Vue.filter("slash2hr", function (value) {
    const result = value.split("-------------------------------").join("<hr>");
    return result;
  });

  Vue.filter("capitalize", function (value) {
    if (!value) return "";
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
  });

  Vue.filter("uppercase", function (value) {
    if (!value) return "";
    value = value.toString();
    return value.toUpperCase();
  });
  Vue.prototype.$can = function (param) {
    const loggedUserPermissions = this.$store.state.auth.user?.permissions;
    if (!loggedUserPermissions) {
      return false;
    }
    const accessType = {
      noAccess: 0,
      view: 1,
      edit: 2,
      create: 3,
      delete: 4,
      all: 5,
    };
    const key_map = {
      adminUser: "admin_users",
      clients: "clients",
      providers: "providers",
      payrollReport: "payroll_report",
      billingReport: "billing_report",
      maxHoursReport: "max_hours_report",
      classes: "classes",
      services: "services",
      professions: "professions",
      document: "document",
    };
    if (loggedUserPermissions[key_map[param.key]] === 0) {
      return false;
    } // special case for noAccess

    if (
      accessType[param.expectedPermission] === 3 &&
      loggedUserPermissions[key_map[param.key]] === 4
    ) {
      return false;
    } // special case for create/delete permission

    if (
      loggedUserPermissions[key_map[param.key]] <
      accessType[param.expectedPermission]
    ) {
      return false;
    }
    return true;
  };
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
